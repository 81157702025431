<template>
  <div class="error-correction">
    <div
      v-if="isShowSubmit"
      class="error-correction-box"
      :style="{ top: show ? '35%' : '50%' }"
    >
      <div class="top">
        <img
          src="https://mobile.oss.farbun.com/%E7%BC%96%E7%BB%84%205.png"
          alt=""
        />
        <div class="top-title">
          <div class="top-title-max">纠错赠送</div>
          <div style="margin-top:20px">一周律呗会员</div>
        </div>
      </div>
      <div class="body">
        <div class="body-title">
          请输入<span style="color: #FF9A02">{{ time }}</span
          >年<span style="color: #FF9A02">{{ address }}</span
          ><span style="color: #FF9A02">{{ name }}</span>
        </div>
        <div class="input-box">
          <input
            class="box-input"
            readonly
            clickable
            v-model="dataContent"
            @touchstart.stop="show = true"
            placeholder="请输入内容"
          />
        </div>
        <div class="btn-box">
          <van-button type="primary" @click="submit">提交</van-button>
        </div>
        <div class="body-content">
          *经核实确认无误后送出，会对第一个纠错的用户奖励一周的会员
        </div>
      </div>
    </div>
    <div v-else class="bottom-box">
      <div class="font-size-18">
        {{ time }}年{{ address }}{{ name }}：{{ value }}{{ unit }}
      </div>
      <div
        class="font-size-18"
        style="color: #99CC99;margin-top:10px;text-align: right;"
        @click="handleClick"
      >
        纠错
      </div>
    </div>
    <div class="zz" @click="close"></div>

    <!-- <div v-if="isShowSubmit">
      <div>
        请输入<span style="color: red">{{ time }}</span
        >年<span style="color: red">{{ address }}</span
        ><span style="color: red">{{ name }}</span
        >：
      </div>
      <div class="box">
        <input
          class="box-input"
          readonly
          clickable
          v-model="dataContent"
          @touchstart.stop="show = true"
          placeholder="请输入内容"
        />
        {{ unit }}
      </div>
      <div class="box">
        <van-button type="primary" @click="submit">提交</van-button>
      </div>
      <div style="color:#999;" class="font-size-12">
        经核实确认无误后，会对第一个纠错的用户奖励1周的会员使用时间。
      </div>
    </div> -->
    <!-- <div v-else>
      <div class="font-size-18">
        {{ time }}年{{ address }}{{ name }}：{{ value }}{{ unit }}
      </div>
      <div
        class="font-size-18"
        style="margin-top:20px;text-align: right;color: #99CC99;"
        @click="isShowSubmit = true"
      >
        纠错
      </div>
    </div> -->

    <van-number-keyboard
      :show="show"
      theme="custom"
      extra-key="."
      close-button-text="完成"
      @blur="show = false"
      @input="onInput"
      @delete="onDelete"
    />
  </div>
</template>
<script>
export default {
  name: 'error-correction',
  props: {
    time: {
      type: Number
    },
    address: {
      type: String
    },
    name: {
      type: String
    },
    unit: {
      type: String
    },
    value: {
      default: () => 0
    }
  },
  components: {},
  data() {
    return {
      isShowSubmit: false,
      dataContent: '',
      show: false
    }
  },
  computed: {},
  methods: {
    handleClick() {
      this.isShowSubmit = true
      this.show = true
    },
    onInput(value) {
      console.log(value)
      this.dataContent += value + ''
    },
    onDelete() {
      const str = this.dataContent + ''
      this.dataContent = str.substring(0, str.length - 1)
    },
    submit() {
      if (this.dataContent === '') {
        return this.$notify('结果不能为空')
      }
      const data = {
        dataContent: this.dataContent,
        year: this.time,
        dataType: this.name,
        province: this.address
      }
      this.$axios.post(`${this.$base}/admin/peopleData/add`, data).then(res => {
        if (res.data.code === 200) {
          this.$notify({
            type: 'success',
            message: '提交成功'
          })
          this.dataContent = ''
          this.isShowSubmit = false
          this.$emit('close')
        }
      })
    },
    close() {
      this.$emit('close')
    }
  },
  mounted() {},
  created() {},
  watch: {
    dataContent(val) {
      this.dataContent = val.replace(/[^\d.]/g, '')
      this.dataContent = val.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
    }
  }
}
</script>
<style lang="stylus" scoped>
.error-correction
  position fixed
  width 100%
  height 100vh
  top 0
  left 0
  z-index 998
  & .bottom-box
    width 100%
    position fixed
    z-index 1000
    bottom 0
    top 80%
    right 0
    padding 20px
    box-sizing border-box
    line-height 30px
    align-items center
    background-color #ffffff
    border-radius 10px 10px 0 0
  & .error-correction-box
    width 90%
    position fixed
    z-index 1000
    left 50%
    top 35%
    transform translate(-50%, -50%)
  & .top
    width 100%
    height 120px
    position relative
    & .top-title
      margin-left 20px
      padding-top 40px
    & > img
      width 100%
      height 100%
      position absolute
      left 0
      top 0
      z-index -1
  & .body
    background #FFF
    padding 20px 20px
    box-sizing border-box
    & .body-title
      font-size: 14px
      margin-bottom  10px
    & .btn-box
      margin-top 20px
      display flex
      align-items center
      justify-content center
      & .van-button--normal
        background #FF9A02
        border-color #FF9A02
  & .zz
    position fixed
    width 100%
    height 100vh
    background rgba(0,0,0,0.5)
    z-index 999
  & .input-box
    height 40px
  & .box-input
    height 30px
    text-align center
    margin-top 1px
    box-sizing border-box
    width 100%
  & .body-content
    color:#999;
    font-size:12px;
    margin-top: 20px
  & .top-title-max
    color:#2D2821;
    font-size:24px
  & .van-number-keyboard
    z-index:1000;
</style>
