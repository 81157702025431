<template>
  <div class="address-selector">
    <van-field class="vans" v-model="addressCopy" placeholder="省份" @input="inputCity" @focus="cityFocus" @blur="cityBlur" />
    <ul class="numlist" v-show="cityShow" @scroll="cityOnLoad($event)">
      <li v-for="(item, i) in cityList" :key="i" @click="cityClick(item.shortName)">
        {{ item.shortName }}
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'address-selector',
  components: {},
  props: {
    address: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    ...mapState('user', ['userInfo']),
  },
  data() {
    return {
      addressCopy: '',
      cityShow: false,
      cityPage: 0,
      cityList: [],
    }
  },
  methods: {
    // 获取城市列表
    getCityList() {
      this.$axios.post(`${this.$base}/document/basicAdministrativeRegion/queryByParams?name=${this.addressCopy}&pageNum=${this.cityPage}&level=1&pageSize=50`).then((res) => {
        const { code, data } = res.data
        if (code === '200') {
          // 如果页码是0，那就替换原来的数组
          if (this.cityPage === 0) {
            this.cityList = data.items
            if (this.userInfo && this.userInfo.province && !this.addressCopy) {
              this.cityList.unshift({ shortName: this.userInfo.province })
            }
          } else {
            // 如果页码不是0，那就在原来的数组的基础上加上数据
            this.cityList = this.cityList.concat(data.items)
          }

          // 如果返回的数据的长度小于每页的大小，那就说明没数据了，不允许继续下拉
          if (data.items.length < this.size) {
            this.isRequest = false
          } else {
            this.isRequest = true
          }
        }
      })
    },
    cityOnLoad(e) {
      if (e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight && this.isRequest) {
        this.cityPage++
        this.getCityList()
      }
    },
    // 城市列表点击事件
    cityClick(item) {
      // 将用户选择的省保存在本地
      console.log(item, 'item')
      localStorage.setItem('province', JSON.stringify(item))
      this.addressCopy = item

      this.isSelect = true

      this.$emit('handleSelect', item)
    },
    cityFocus() {
      this.cityShow = true
      this.isSelect = false
      this.getCityList()
    },
    cityBlur() {
      this.cityPage = 0
      setTimeout(() => {
        this.cityShow = false
        if (this.isSelect === false) {
          this.addressCopy = ''
          this.$emit('upAddress', this.addressCopy)
        }
      }, 200)
    },
    inputCity() {
      console.log('inputCity-', this.addressCopy)
      this.$emit('upAddress', this.addressCopy)
      this.cityPage = 0
      this.getCityList()
    },
  },
  mounted() {
    const provinceData = localStorage.getItem('province')

    let province = ''

    if (provinceData) {
      try {
        province = JSON.parse(provinceData)
      } catch (error) {
        // console.error('Error parsing province data:', error)
        // 可以添加适当的错误处理逻辑，比如提醒用户或使用默认值
      }
    }

    this.cityClick(province || '')
    this.getCityList()
    this.addressCopy = province
  },
  // watch: {
  //   address(val) {
  //     // this.addressCopy = val
  //   },
  // },
}
</script>

<style lang="stylus" scoped>
.address-selector
  position relative
  width 100%
  & .van-cell
    padding 10px 0
    border-bottom: 1px solid #ccc;
  & .numlist
    width 95%
    max-height 180px
    overflow auto
    background rgba(255, 255, 255, 1)
    box-shadow 0px 3px 8px 1px rgba(190, 190, 190, 0.64)
    border-radius 5px
    position fixed
    z-index 999
    left 2%
    li
      height 35px
      line-height 35px
      text-indent 10px
      white-space normal
      overflow hidden
      text-overflow ellipsis
      font-size 14px
      color #333
      & .org-title
        width 100%
        display flex
        flex-direction row
        align-items center
        justify-content space-between
        padding 0 20px
        box-sizing border-box
</style>
