var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "address-selector" },
    [
      _c("van-field", {
        staticClass: "vans",
        attrs: { placeholder: "请输入行业", maxlength: "8" },
        on: { input: _vm.inputCity, focus: _vm.cityFocus, blur: _vm.cityBlur },
        model: {
          value: _vm.industryKeyword,
          callback: function ($$v) {
            _vm.industryKeyword = $$v
          },
          expression: "industryKeyword",
        },
      }),
      _c(
        "ul",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.cityShow,
              expression: "cityShow",
            },
          ],
          staticClass: "numlist",
          on: {
            scroll: function ($event) {
              return _vm.cityOnLoad($event)
            },
          },
        },
        _vm._l(_vm.cityList, function (item, i) {
          return _c(
            "li",
            {
              key: i,
              on: {
                click: function ($event) {
                  return _vm.cityClick(item)
                },
              },
            },
            [_vm._v(" " + _vm._s(item.relationAttrValue) + " ")]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }