var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "error-correction" },
    [
      _vm.isShowSubmit
        ? _c(
            "div",
            {
              staticClass: "error-correction-box",
              style: { top: _vm.show ? "35%" : "50%" },
            },
            [
              _vm._m(0),
              _c("div", { staticClass: "body" }, [
                _c("div", { staticClass: "body-title" }, [
                  _vm._v(" 请输入"),
                  _c("span", { staticStyle: { color: "#FF9A02" } }, [
                    _vm._v(_vm._s(_vm.time)),
                  ]),
                  _vm._v("年"),
                  _c("span", { staticStyle: { color: "#FF9A02" } }, [
                    _vm._v(_vm._s(_vm.address)),
                  ]),
                  _c("span", { staticStyle: { color: "#FF9A02" } }, [
                    _vm._v(_vm._s(_vm.name)),
                  ]),
                ]),
                _c("div", { staticClass: "input-box" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.dataContent,
                        expression: "dataContent",
                      },
                    ],
                    staticClass: "box-input",
                    attrs: {
                      readonly: "",
                      clickable: "",
                      placeholder: "请输入内容",
                    },
                    domProps: { value: _vm.dataContent },
                    on: {
                      touchstart: function ($event) {
                        $event.stopPropagation()
                        _vm.show = true
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.dataContent = $event.target.value
                      },
                    },
                  }),
                ]),
                _c(
                  "div",
                  { staticClass: "btn-box" },
                  [
                    _c(
                      "van-button",
                      { attrs: { type: "primary" }, on: { click: _vm.submit } },
                      [_vm._v("提交")]
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "body-content" }, [
                  _vm._v(
                    " *经核实确认无误后送出，会对第一个纠错的用户奖励一周的会员 "
                  ),
                ]),
              ]),
            ]
          )
        : _c("div", { staticClass: "bottom-box" }, [
            _c("div", { staticClass: "font-size-18" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.time) +
                  "年" +
                  _vm._s(_vm.address) +
                  _vm._s(_vm.name) +
                  "：" +
                  _vm._s(_vm.value) +
                  _vm._s(_vm.unit) +
                  " "
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "font-size-18",
                staticStyle: {
                  color: "#99CC99",
                  "margin-top": "10px",
                  "text-align": "right",
                },
                on: { click: _vm.handleClick },
              },
              [_vm._v(" 纠错 ")]
            ),
          ]),
      _c("div", { staticClass: "zz", on: { click: _vm.close } }),
      _c("van-number-keyboard", {
        attrs: {
          show: _vm.show,
          theme: "custom",
          "extra-key": ".",
          "close-button-text": "完成",
        },
        on: {
          blur: function ($event) {
            _vm.show = false
          },
          input: _vm.onInput,
          delete: _vm.onDelete,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "top" }, [
      _c("img", {
        attrs: {
          src: "https://mobile.oss.farbun.com/%E7%BC%96%E7%BB%84%205.png",
          alt: "",
        },
      }),
      _c("div", { staticClass: "top-title" }, [
        _c("div", { staticClass: "top-title-max" }, [_vm._v("纠错赠送")]),
        _c("div", { staticStyle: { "margin-top": "20px" } }, [
          _vm._v("一周律呗会员"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }