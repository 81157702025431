<template>
  <div class="address-selector">
    <van-field
      class="vans"
      v-model="industryKeyword"
      placeholder="请输入行业"
      @input="inputCity"
      @focus="cityFocus"
      @blur="cityBlur"
      maxlength="8"
    />
    <ul class="numlist" v-show="cityShow" @scroll="cityOnLoad($event)">
      <li v-for="(item, i) in cityList" :key="i" @click="cityClick(item)">
        {{ item.relationAttrValue }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'address-selector',
  components: {},
  props: ['address', 'industry', 'time'],
  data() {
    return {
      industryKeyword: '',
      currentPage: 1,
      cityShow: false,
      cityList: []
    }
  },
  computed: {},
  methods: {
    // 获取城市列表
    getCityList() {
      const params = {
        currentPage: this.currentPage,
        year: this.time,
        dataType: '平均工资',
        relationAttrType: '行业',
        relationAttrValue: this.industryKeyword
      }
      if (this.address) {
        if (this.address.split('/').length === 3) {
          params.city = this.address.split('/')[1]
        } else {
          params.city = this.address
        }
      } else {
        params.city = this.address
      }
      this.$axios
        .get(`${this.$base}/admin/peopleData/query`, {
          params: params
        })
        .then(res => {
          const { code, data } = res.data
          if (code === '200') {
            // 如果页码是1，那就替换原来的数组
            if (this.currentPage === 1) {
              this.cityList = data.list
            } else {
              // 如果页码不是0，那就在原来的数组的基础上加上数据
              this.cityList = this.cityList.concat(data.list)
            }
            // 如果返回的数据的长度小于每页的大小，那就说明没数据了，不允许继续下拉
            if (data.list.length < this.size) {
              this.isRequest = false
            } else {
              this.isRequest = true
            }
          }
        })
    },
    cityOnLoad(e) {
      if (
        e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight &&
        this.isRequest
      ) {
        this.currentPage++
        this.getCityList()
      }
    },
    // 城市列表点击事件
    cityClick(item) {
      this.addressCopy = item.relationAttrValue
      this.$emit('handleSelect', item)
    },
    cityFocus() {
      this.cityShow = true
      this.getCityList()
    },
    cityBlur() {
      this.currentPage = 1
      setTimeout(() => {
        this.cityShow = false
      }, 200)
    },
    inputCity() {
      this.$emit('upIndustry', this.industryKeyword)
      this.currentPage = 1
      this.getCityList()
    }
  },
  mounted() {},
  created() {},
  watch: {
    industry(val) {
      this.industryKeyword = val
      // this.querySearchAsync()
    }
  }
}
</script>

<style lang="stylus" scoped>
.address-selector
  position relative
  width 100%
  & .van-cell
    padding 10px 0
  & .numlist
    width 95%
    max-height 180px
    overflow auto
    background rgba(255, 255, 255, 1)
    box-shadow 0px 3px 8px 1px rgba(190, 190, 190, 0.64)
    border-radius 5px
    position fixed
    z-index 999
    left 2%
    li
      height 35px
      line-height 35px
      text-indent 10px
      white-space normal
      overflow hidden
      text-overflow ellipsis
      font-size 14px
      color #333
      & .org-title
        width 100%
        display flex
        flex-direction row
        align-items center
        justify-content space-between
        padding 0 20px
        box-sizing border-box
</style>
